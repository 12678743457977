<template>
  <div class="control">
    <div id="cameraArea"></div>
  </div>
</template>

<script>
import Quagga from "@ericblade/quagga2";

export default {
  name: "BarcodeScanner",
  data() {
    return {
      code: "",
    };
  },
  props: {
    scan: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    scan(value) {
      if (value) {
        this.startScan();
      } else {
        this.stopScan();
      }
    },
  },
  methods: {
    startScan() {
      this.code = "";
      this.initQuagga();
    },
    stopScan() {
      Quagga.offProcessed(this.onProcessed);
      Quagga.offDetected(this.onDetected);
      Quagga.stop();
    },
    initQuagga() {
      Quagga.onProcessed(this.onProcessed);
      Quagga.onDetected(this.onDetected);

      const config = {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: document.querySelector("#cameraArea"),
          constraints: { facingMode: "environment" },
        },
        numOfWorkers: navigator.hardwareConcurrency || 4,
        decoder: { readers: ["ean_reader"] },
      };
      Quagga.init(config, this.onInit);
    },
    onInit(err) {
      if (err) {
        console.error(err);
        this.$emit("error", err);
        return;
      }
      Quagga.start();
    },
    onDetected(success) {
      if (!success.codeResult.code.startsWith("978")) {
        // ISBN13のバーコードじゃなければやり直し
        return;
      }
      this.code = success.codeResult.code;
      Quagga.stop();
      this.$emit("detect", this.code);
    },
    onProcessed(result) {
      const drawingCtx = Quagga.canvas.ctx.overlay;
      const drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        // 検出中の緑の線の枠
        if (result.boxes) {
          drawingCtx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);
          result.boxes
            .filter((box) => box !== result.box)
            .forEach((box) => {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: "green",
                lineWidth: 2,
              });
            });
        }

        // 検出に成功した瞬間の青い線の枠
        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: "blue",
            lineWidth: 2,
          });
        }

        // 検出に成功した瞬間の水平の赤い線
        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: "x", y: "y" },
            drawingCtx,
            {
              color: "red",
              lineWidth: 3,
            }
          );
        }
      }
    },
  },
};
</script>

<style>
#cameraArea {
  overflow: hidden;
  width: 100%;
  /* height: 240px; */
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
}
#cameraArea video,
#cameraArea canvas {
  width: 100%;
  /* height: 240px; */
}

.drawingBuffer {
  position: absolute;
  left: 0;
}
</style>
