<template>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ form.title || "未記入" }}</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <h2 class="is-size-5 mb-3">
          <span>書誌情報</span>
          <b-tag :type="bookStatus.type" class="is-pulled-right">
            {{ bookStatus.label }}
          </b-tag>
        </h2>
        <div class="columns is-gapless mb-2">
          <b-field
            label="ISBN"
            :type="hasError('isbn_code')"
            :message="message('isbn_code')"
            class="column is-8"
          >
            <b-input
              icon="numeric"
              v-model="form.isbn_code"
              maxlength="13"
              :has-counter="false"
              expanded
              :readonly="!isEditing"
              :disabled="!isEditing"
            ></b-input>
            <p class="control">
              <b-button
                :type="isEditing ? 'is-warning is-light' : 'is-light'"
                icon-left="barcode-scan"
                :label="isScanning ? 'キャンセル' : '読み取り'"
                @click="toggleBarcodeScanner"
                :disabled="!isEditing"
              />
            </p>
          </b-field>

          <b-field class="column">
            <p class="control">
              <b-button
                :type="isEditing ? 'is-primary is-light' : 'is-light'"
                icon-left="magnify"
                label="外部書誌情報検索"
                @click="searchBookDetail"
                expanded
                :disabled="
                  !isEditing ||
                  form.isbn_code === null ||
                  form.isbn_code.length < 10
                "
              />
            </p>
          </b-field>
        </div>

        <b-field v-show="isScanning">
          <barcode-scanner
            :scan="isScanning"
            @detect="handleISBNDetected"
            @error="handleCameraRejected"
          />
        </b-field>

        <b-field
          label="タイトル"
          :type="hasError('title')"
          :message="message('title')"
        >
          <b-input
            icon="alphabetical-variant"
            v-model="form.title"
            required
            maxlength="256"
            :has-counter="false"
            :readonly="!isEditing"
            :disabled="!isEditing"
          ></b-input>
        </b-field>

        <b-field
          label="副題"
          :type="hasError('subtitle')"
          :message="message('subtitle')"
        >
          <b-input
            icon="alphabetical-variant"
            v-model="form.subtitle"
            :readonly="!isEditing"
            :disabled="!isEditing"
          ></b-input>
        </b-field>

        <div class="columns is-gapless">
          <b-field class="column is-3 mr-2 has-text-centered">
            <b-upload
              v-model="form.file"
              drag-drop
              accept="image/jpeg"
              validationMessage="画像ファイルを選択してください"
              v-if="isEditing"
              class="mb-3"
            >
              <div class="preview" v-if="preview">
                <img :src="preview" width="128" alt="書影" />
              </div>
              <section class="section" v-else>
                <div class="content has-text-centered">
                  <p>
                    <b-icon icon="image" size="is-large"></b-icon>
                  </p>
                  <p>書影</p>
                </div>
              </section>
            </b-upload>
            <figure class="preview is-bordered mb-3" v-else>
              <img :src="preview" width="128" alt="書影" v-if="preview" />
              <div
                v-else
                class="has-text-centered has-background-light no-image"
              >
                書影なし
              </div>
            </figure>
          </b-field>
          <div class="column">
            <b-field
              label="著者（Tabキーで確定）"
              :type="hasError('authors')"
              :message="message('authors')"
            >
              <b-taginput
                icon="fountain-pen"
                v-model="form.authors"
                field="author_name"
                :closable="isEditing"
                autocomplete
                :data="authors"
                allow-new
                :confirm-keys="['Tab', ',']"
                @typing="(v) => (authorFilter = v)"
                :placeholder="isEditing ? '著者を追加' : ''"
                :readonly="!isEditing"
                :disabled="!isEditing"
              ></b-taginput>
            </b-field>

            <b-field
              label="出版社"
              :type="hasError('publisher')"
              :message="message('publisher')"
            >
              <b-autocomplete
                icon="office-building"
                v-model="form.publisher"
                :data="publishers"
                select-on-click-outside
                :placeholder="isEditing ? '例) オライリー・ジャパン' : ''"
                keep-first
                :confirm-keys="['Tab']"
                maxlength="30"
                :has-counter="false"
                :readonly="!isEditing"
                :disabled="!isEditing"
              >
                <template #empty>{{ form.publisher }}は未登録です</template>
              </b-autocomplete>
            </b-field>

            <b-field grouped>
              <b-field
                label="出版年月"
                :type="hasError('published_date')"
                :message="message('published_date')"
                expanded
              >
                <b-input
                  type="text"
                  icon="calendar-today"
                  :placeholder="isEditing ? 'yyyy-mm(-dd)' : ''"
                  pattern="[0-9]{4}-[0-9]{2}(-[0-9]{2})?"
                  title="yyyy-mm or yyyy-mm-dd"
                  v-model="form.published_date"
                  :readonly="!isEditing"
                  :disabled="!isEditing"
                ></b-input>
              </b-field>
              <b-field
                label="Cコード"
                :type="hasError('c_code')"
                :message="message('c_code')"
                expanded
                class="c-code"
              >
                <b-input
                  icon="shape"
                  :placeholder="isEditing ? 'C####' : ''"
                  pattern="C[0-9]{4}"
                  v-model="form.c_code"
                  :has-counter="false"
                  maxlength="5"
                  :readonly="!isEditing"
                  :disabled="!isEditing"
                ></b-input>
                <p class="control">
                  <b-tooltip
                    type="is-primary is-light"
                    :label="cCodeText"
                    position="is-left"
                  >
                    <b-button icon-left="information-outline" type="is-light" />
                  </b-tooltip>
                </p>
              </b-field>
            </b-field>
            <b-field
              label="タグ（Tabキーで確定）"
              :type="hasError('tags')"
              :message="message('tags')"
            >
              <b-taginput
                icon="tag"
                v-model="form.tags"
                field="tag_name"
                :closable="isEditing"
                autocomplete
                :data="tags"
                allow-new
                :confirm-keys="['Tab', ',']"
                @typing="(v) => (tagFilter = v)"
                :placeholder="isEditing ? 'タグを追加' : ''"
                :readonly="!isEditing"
                :disabled="!isEditing"
              ></b-taginput>
            </b-field>
          </div>
        </div>

        <hr />
        <h2 class="is-size-5 mb-3">所蔵情報</h2>

        <b-field
          grouped
          v-for="(item, index) in form.stored_books"
          :key="'book-' + index"
          :type="item.$delete ? 'is-danger' : ''"
        >
          <b-field
            label="保管場所"
            :type="hasError('stored_books.*.bookshelf_address')"
            :message="message('stored_books.*.bookshelf_address')"
            expanded
          >
            <b-select
              icon="library-shelves"
              v-model="item.bookshelf_address"
              :disabled="!isEditing || item.$delete"
              expanded
            >
              <option
                v-for="option in bookshelves"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
                {{ option.value }}
              </option>
            </b-select>
          </b-field>

          <b-field
            label="所有者"
            :type="hasError('stored_books.*.user_id')"
            :message="message('stored_books.*.user_id')"
            expanded
          >
            <b-select
              icon="account"
              v-model="item.user_id"
              expanded
              :disabled="!isEditing || item.$delete"
              :loading="owners === undefined || owners.length === 0"
            >
              <option
                v-for="option in owners"
                :value="option.id"
                :label="option.name"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
          <b-button
            :type="
              isEditing
                ? item.$delete
                  ? 'is-primary is-light'
                  : 'is-danger is-light'
                : 'is-light'
            "
            :icon-left="item.$delete ? 'undo' : 'minus'"
            @click="removeStoredBook(index)"
            :disabled="!isEditing"
          />
        </b-field>
        <b-field>
          <p class="control">
            <b-button
              type="is-primary is-light"
              icon-left="plus"
              label="追加"
              size="is-small"
              @click="addStoredBook"
              expanded
              v-if="isEditing"
            />
          </p>
        </b-field>
      </section>

      <footer class="modal-card-foot">
        <b-button
          label="登録解除"
          :type="isEditing && !isNew ? 'is-danger' : 'is-light'"
          @click="deactivateBook"
          :loading="storing"
          :disabled="!canDeactivate"
          expanded
        />

        <b-button
          label="保存"
          :type="isEditing ? 'is-success' : 'is-light'"
          @click="saveBook"
          :loading="storing"
          :disabled="!isEditing"
          expanded
        />
        <b-button
          :label="isEditing ? '取消' : '編集'"
          :type="isNew ? 'is-light' : 'is-warning'"
          @click="toggleEditMode"
          :disabled="isNew"
          expanded
        />
        <b-button
          label="借りる"
          :type="isEditing ? 'is-light' : 'is-primary'"
          @click="borrowBook"
          expanded
          :disabled="isEditing || !canBorrow"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import BarcodeScanner from "../components/BarcodeScanner.vue";
import {
  BOOKSHELF_ADDRESS,
  C_CODE_CATEGORY,
  C_CODE_FORMAT,
  C_CODE_TARGET,
} from "../constants";

import fetchMixin from "../fetch-mixin";
import store from "../store";
import storeMixin, { clone } from "../store-mixin";

function initialForm(isNew) {
  if (isNew) {
    store.commit("my/setBook", {
      id: null,
      isbn_code: null,
      title: null,
      subtitle: null,
      image_link: null, // URL(string)
      file: null, // File(binary)
      authors: [],
      publisher: null,
      published_date: null,
      c_code: null,
      tags: [],
      stored_books: [{ bookshelf_address: null, user_id: 0 }],
    });
  }
  return clone(store.state.my.book_form);
}

const C_CODE_ID = "78";

async function callGoogleBookSearchAPI(isbn) {
  try {
    const response = await fetch(
      `https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`
    );
    const json = await response.json();
    // console.log(json);
    if (json.totalItems > 0) {
      const info = json.items[0].volumeInfo;
      return {
        title: info.title,
        subtitle: info.subtitle,
        image_link: info.imageLinks?.thumbnail,
        authors: info.authors,
        publisher: info.publisher,
        published_date: info.publishedDate,
      };
    }
  } catch (e) {
    console.log(e);
  }
  return null;
}

async function callOpenBDAPI(isbn, items) {
  try {
    const response = await fetch(`https://api.openbd.jp/v1/get?isbn=${isbn}`);
    const json = await response.json();
    // console.log(json);
    const info = json[0];
    if (info) {
      const titleCandidate =
        info?.onix?.DescriptiveDetail?.TitleDetail?.TitleElement?.TitleText
          ?.content;
      let titles = [null, null];
      if (titleCandidate) {
        if (titleCandidate.includes(" : ")) {
          titles = titleCandidate.split(" : ");
        } else {
          titles = [
            titleCandidate,
            info?.onix?.DescriptiveDetail?.TitleDetail?.TitleElement?.Subtitle
              ?.content,
          ];
        }
      }

      const publisherCandidate =
        info?.onix?.PublishingDetail?.Imprint?.ImprintName;
      let publisher;
      if (publisherCandidate) {
        if (publisherCandidate.includes(" : ")) {
          publisher = publisherCandidate.split(" : ")[0];
        } else {
          publisher = publisherCandidate;
        }
      }

      const subject = info.onix.DescriptiveDetail.Subject?.find(
        (v) => v.SubjectSchemeIdentifier === C_CODE_ID
      );

      return {
        title: items?.title ?? titles[0],
        subtitle: items?.subtitle ?? titles[1],
        image_link:
          items?.image_link ?? info?.summary?.cover?.replace("\\", ""),
        authors: items?.authors,
        publisher: items?.publisher ?? publisher,
        published_date: items?.published_date,
        c_code: subject ? "C" + subject.SubjectCode : null,
      };
    }
  } catch (e) {
    console.log(e);
  }
  return { ...items, c_code: null };
}

function displayCCodeTarget(c_code) {
  if (c_code === null || c_code.length < 2) return "不明";
  return C_CODE_TARGET[c_code[1]] || "エラー";
}

function displayCCodeFormat(c_code) {
  if (c_code === null || c_code.length < 3) return "不明";
  return C_CODE_FORMAT[c_code[2]] || "エラー";
}

function displayCCodeCategory(c_code) {
  if (c_code === null || c_code.length < 5) return "不明";
  return C_CODE_CATEGORY[c_code.substr(3, 2)] || "エラー";
}

export default {
  components: { BarcodeScanner },
  name: "BookForm",
  mixins: [fetchMixin, storeMixin],
  props: {
    bookId: String,
  },
  data() {
    const isNew = this.bookId === "new";
    return {
      form: initialForm(isNew),
      formBackup: null,
      isEditing: isNew,
      isScanning: false,
      authorFilter: "",
      tagFilter: "",
    };
  },
  computed: {
    bookshelves() {
      return [
        { value: null, label: "なし" },
        ...Object.values(BOOKSHELF_ADDRESS),
      ];
    },
    owners() {
      return [
        { id: null, name: "なし" },
        ...this.$store.state.my.usernames,
      ].map((v) =>
        v.id === 0
          ? {
              id: v.id,
              name: "会社所有",
            }
          : v
      );
    },
    publishers() {
      return this.$store.state.my.bookPublishers.filter((v) =>
        v.startsWith(this.form.publisher)
      );
    },
    authors() {
      return this.$store.state.my.bookAuthors.filter((v) =>
        v.startsWith(this.authorFilter)
      );
    },
    cCodeText() {
      const c_code = this.form.c_code;
      return (
        displayCCodeTarget(c_code) +
        "/" +
        displayCCodeFormat(c_code) +
        "/" +
        displayCCodeCategory(c_code)
      );
    },
    tags() {
      return this.$store.state.my.bookTags.filter((v) =>
        v.startsWith(this.tagFilter)
      );
    },
    preview() {
      if (this.form.file instanceof File) {
        return window.URL.createObjectURL(this.form.file);
      } else if (
        this.form.image_link === null ||
        this.form.image_link === undefined
      ) {
        return "";
      } else {
        return this.form.image_link;
      }
    },
    isNew() {
      return this.bookId === "new";
    },
    canBorrow() {
      return (
        !this.isNew &&
        this.form.stored_books?.some(
          (v) =>
            v.bookshelf_address !== null &&
            (v.rental === null ||
              v.rental === undefined ||
              v.rental.returned_at !== null)
        )
      );
    },
    hasStoredBooks() {
      return (
        this.form.stored_books !== undefined &&
        this.form.stored_books !== null &&
        this.form.stored_books.length > 0
      );
    },
    canDeactivate() {
      return this.isEditing && !this.hasStoredBooks;
    },
    isBookShelvesUnknown() {
      return this.form.stored_books?.every((v) => v.bookshelf_address === null);
    },
    bookStatus() {
      if (this.isNew) {
        return { type: "is-warning", label: "新規" };
      } else {
        if (this.hasStoredBooks) {
          if (this.canBorrow) {
            return { type: "is-success", label: "貸出可" };
          } else if (this.isBookShelvesUnknown) {
            return { type: "is-light", label: "保管場所不明" };
          } else {
            return { type: "is-danger", label: "貸出中" };
          }
        } else {
          return { type: "is-light", label: "所蔵なし" };
        }
      }
    },
  },
  methods: {
    addStoredBook() {
      const item = {
        id: null,
        bookshelf_address: null,
        user_id: null,
      };
      if (this.form.stored_books === undefined) {
        this.form.stored_books = [item];
      } else {
        this.form.stored_books.push(item);
      }
    },
    removeStoredBook(index) {
      const item = this.form.stored_books[index];
      if (item.id === null) {
        this.form.stored_books.splice(index, 1);
      } else {
        this.$set(item, "$delete", !item.$delete);
      }
    },
    toggleEditMode() {
      if (this.isEditing) {
        this.form = clone(this.formBackup);
      } else {
        this.formBackup = clone(this.form);
      }
      this.isEditing = !this.isEditing;
    },
    async searchBookDetail() {
      if (this.form.isbn_code === null || this.form.isbn_code.length < 10) {
        return;
      }
      try {
        const result = await callOpenBDAPI(
          this.form.isbn_code,
          await callGoogleBookSearchAPI(this.form.isbn_code)
        );

        // console.log(result);
        if (result) {
          this.form.title = result.title;
          this.form.subtitle = result.subtitle;
          this.form.image_link = result.image_link;
          this.form.file = null;
          this.form.authors = result.authors;
          this.form.publisher = result.publisher;
          this.form.published_date = result.published_date;
          this.form.c_code = result.c_code;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async saveBook() {
      const form = clone(this.form);
      form.file = this.form.file; // Fileオブジェクトはクローン出来ない
      if (form.file) {
        form.image_link = null;
      }
      form.authors = form.authors.map((v) =>
        typeof v === "object" ? v : { id: null, author_name: v }
      );
      form.tags = form.tags.map((v) =>
        typeof v === "object" ? v : { id: null, tag_name: v }
      );
      form.stored_books = form.stored_books
        ?.filter((v) => !v.$delete)
        ?.map((v) => ({
          bookshelf_address: v.bookshelf_address,
          user_id: v.user_id,
        }));

      // 日付情報の正規化
      if (form.published_date?.length < 4) {
        form.published_date = null;
      } else if (form.published_date?.length === 4) {
        form.published_date += "-01-01";
      } else if (form.published_date?.length === 7) {
        form.published_date += "-01";
      }

      try {
        await this.store("my/saveBook", form);
        this.form = { ...initialForm() };
        this.$emit("close");
      } catch (e) {
        console.log(e);
      }
    },
    async deactivateBook() {
      this.$buefy.dialog.confirm({
        title: "確認",
        message: `書籍一覧から登録解除します`,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.store("my/deactivateBook", this.form.id);
            this.$emit("close");
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    async borrowBook() {
      try {
        const storedBook = this.form.stored_books.find(
          (v) =>
            v.rental === null ||
            v.rental === undefined ||
            v.rental.returned_at !== null
        );
        await this.store("my/borrowBook", {
          bookId: this.form.id,
          storedBookId: storedBook.id,
        });
        this.$emit("close");
      } catch (e) {
        console.log(e);
      }
    },
    toggleBarcodeScanner() {
      this.isScanning = !this.isScanning;
    },
    async handleISBNDetected(value) {
      this.form.isbn_code = value;
      this.isScanning = false;
      await this.searchBookDetail();
    },
    handleCameraRejected(err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: `カメラが使用できないため読み取りができません。(${err})`,
        position: "is-top",
        type: "is-danger",
      });
      this.toggleBarcodeScanner();
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.fetch("my/listUserName"),
        this.fetch("my/listBookPublisher"),
        this.fetch("my/listBookAuthor"),
        this.fetch("my/listBookTag"),
      ]);
    } catch (e) {
      console.log(e);
    }
  },
  async beforeRouteEnter(to, _from, next) {
    if (to.params.bookId === "new") {
      return next();
    }
    store.commit("setLoading", true);
    try {
      await store.dispatch("my/getBook", to.params.bookId);
      store.commit("setLoading", false);
      next();
    } catch (e) {
      console.log(e);
      store.commit("setError", e.data ? e.data.detail : e.message);
      store.commit("setLoading", false);
      if (e.status) {
        if (e.status === 401) {
          next({ name: "login" });
        } else {
          next({ name: "books" });
        }
      }
    }
  },
};
</script>

<style lang="scss">
.field.column .upload.control {
  height: 100%;
}

.field.c-code > .field-body > .field.has-addons {
  flex-shrink: 1;
}

.preview {
  padding: 0.5rem 0.25rem;

  &.is-bordered {
    border: 1px solid #dbdbdb;
  }

  .no-image {
    padding: 1rem;
    height: 190px;
  }
}
</style>
